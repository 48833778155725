import React, { useState, useRef, useEffect } from 'react'
import "./search.less"
import { navigate } from 'gatsby';
import searchImg from "../images/documentation/search.png"
export default function SearchPage(props) {
  // console.log(props);
  const inputValue = useRef(null)
  const textElement = useRef(null)
  const [num, setNum] = useState(0)
  const [dtaas, setDtaas] = useState(props.listData)
  const [searchList, setSearchList] = useState(false)

  const defaultList = [
    {
      title: "What Data is a Trust",
      text: "DTaaS is a platform for building Data Trusts, and a Data Trust contains Data Assets and Services and Applications built to consume  Services and Applications built to consume and produce those Data Assets. A Data Asset  ",
      link: "",
      id: 0,
      outlineidx: 0
    },
    {
      title: "Object Model",
      text: "DTaaS has a graph-based object model that can be used to model arbitrary application domains and apply access control to the objects in that domain. This abstract model is reified into a few pre-defined object types that we provide an SDK for that allows for further domain-specific implementation on top of those concepts.",
      id: 1,
      outlineidx: 1
    },
    {
      title: "Access Control",
      text: "Access control is represented as abstract Capabilities on objects that are enforced at the HTTP layer. Whenever an HTTP request is made to a DTaaS API or to a Servable, we intercept the request and evaluate whether or not the request is allowed based on the Capabilities that the entity making the request has.",
      id: 2,
      outlineidx: 2
    }
  ]
  const [contentList, setContentList] = useState(defaultList)
  useEffect(() => {
    inputValue.current.value = props.value
    searchData()
    console.log("searchpage");
  },[props.showSearch])
  // let dtaas = props.listData
  let arr = []
  const searchData = () => {
    let val = inputValue.current.value.toLocaleLowerCase()
    if (!val) {
      arr = []
      setSearchList(false)
      setContentList(defaultList)
    } else if (val) {
      arr = []
      for (let i = 0; i < props.listData.length; i++) {
        for (let j = 0; j < props.listData[i].text.length; j++) {
          if (props.listData[i].text[j].title.toLocaleLowerCase().indexOf(val) !== -1) {
            arr.push({
              title: props.listData[i].text[j].title,
              text: props.listData[i].text[j].content[0],
              link: props.listData[i].title,
              id: props.listData[i].id,
              outlineidx: j
            })
          } else {
            for (let h = 0; h < props.listData[i].text[j].content.length; h++) {
              if (props.listData[i].text[j].content[h].toLocaleLowerCase().indexOf(val) !== -1) {
                arr.push({
                  title: props.listData[i].text[j].title,
                  text: props.listData[i].text[j].content[h],
                  link: props.listData[i].title,
                  id: props.listData[i].id,
                  outlineidx: j
                })
                setContentList(arr)
              }
            }
          }
        }
      }
      if(arr.length === 0){
          setSearchList(false)
          setContentList(defaultList)
      }else{
          setSearchList(true)
          setContentList(arr)
      }
    }
    // let reg = new RegExp(val)
    // textElement.current.innerHTML.replace(reg, "<span>aa</span>")
    inputValue.current.value = ""
    props.setValue("")
    

  }
  const keyDown = (e) => {
    if (e.keyCode === 13) {
      searchData()

    }
  }
  const backToList = (id, outlineidx) => {
    props.close(false)
    props.changeColor(id)
    props.changeOutline(outlineidx)
    navigate('#text' + outlineidx)
    setContentList([])
  }
  return (
    <div style={{ display: props.status ? "block" : "none" }} className='search_page'>
      <div className='input'>
        <input placeholder='Search' onKeyDown={keyDown} ref={inputValue} type="text" name="" id="" />
        <div onClick={searchData}><img src={searchImg} alt="" /></div>
      </div>
      <hr />
      <h3 onClick={() => props.close(false)}>{
        searchList ?
          `${contentList.length} related results` :
          "No related documents found"
      }</h3>

      <h6 style={{ display: searchList ? "none" : "block", marginTop: "12rem" }}>You might be looking for</h6>
      <ul ref={textElement} >
        {
          contentList?.map((item, index) => {
            return (
              <li onClick={() => backToList(item.id, item.outlineidx)} key={index}>
                <h3>{item.title}</h3>
                <p >{item.text}</p>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}
