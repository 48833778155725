import React, { useState, useEffect, Fragment } from 'react'
import "../style/documentation.less"
import SearchPage from "../templates/SearchPage.jsx"
import { navigate } from "gatsby"
import Header from '../components/header'
import search from "../images/documentation/search.png"
import book from "../images/documentation/book.png"
import blackBook from "../images/documentation/blackbook.png"
import bigImg from "../images/documentation/bigimg.png"
import arrow from "../images/documentation/arrow.png"
import point from "../images/documentation/point.png"
import bluePoint from "../images/documentation/bluepoint.png"
import select from "../images/documentation/select.png"
import arrowRight from "../images/documentation/right.svg"
import screen from "../images/documentation/screen.png"
import Fade from "react-reveal/Fade"
export default function Documentation() {
    const [selectStatus, setSelectStatus] = useState(0)
    const [outlineStatus, setOutlineStatus] = useState(0)
    const [showDtaas, setShowDtaas] = useState(true)
    const [showSdk, setShowSdk] = useState(false)
    const [hover, setHover] = useState(false)
    const [showSelection, setShowSelection] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const dtaasList = [
        "DTaaS Overview",
        "Prototypes",
        "Servables",
        "Capabilities",
        "dtDomains",
        "dtApps",
        "dtServices"]
    const sdkList = [
        "CLI Commands and Usage",
        "Example",
        "Project Manifest"
    ]
    const outlineList = [
        { text: "What is a Data Trust", link: "text1" },
        { text: "Object Model", link: "text2" }
    ]
    const listData = [
        {
            id: 0,
            title: "DTaaS Overview",
            outline: [
                "What is a Data Trust",
                "Object Model",
                "Access Control",
                "Federation"
            ],
            text: [
                {
                    title: "What is a Data Trust",
                    content: [
                        "DTaaS is a platform for building Data Trusts, and a Data Trust contains Data Assets and Services and Applications built to consume and produce those Data Assets. A Data Asset in DTaaS is any arbitrary data that is exposed to some set of consumers with some API. This means that Data Assets are really code that knows how to store/access data, and that data may live somewhere else.",
                        "The important distinction between a Data Trust and any other distributed system is the granular visibility and control of data assets and derived data assets that is independent of the Application that generated them. Because DTaaS is a platform for building Data Trusts, it does not define what Data Assets are, but instead provides the architecture necessary to define what they are and how they can be used. This allows Applications to be written for DTaaS that expose arbitrary functionality to users, but still be able to grant users visibility into their data assets independently of the Application that created and manages them. DTaaS decouples the problem of cataloging, managing, and sharing data assets from the problem of generating, viewing, and interacting with them. The former set of problems are managed by DTaaS and the later are left up to Applications to implement."
                    ]
                },
                {
                    title: "Object Model",
                    content: [
                        "DTaaS has a graph-based object model that can be used to model arbitrary application domains and apply access control to the objects in that domain. This abstract model is reified into a few pre-defined object types that we provide an SDK for that allows for further domain-specific implementation on top of those concepts.",
                        "The basic building blocks revolve around Kubernetes concepts."
                    ]
                },
                {
                    title: "Access Control",
                    content: [
                        "Access control is represented as abstract Capabilities on objects that are enforced at the HTTP layer. Whenever an HTTP request is made to a DTaaS API or to a Servable, we intercept the request and evaluate whether or not the request is allowed based on the Capabilities that the entity making the request has.",
                        "Granular Capabilities are available for DTaaS APIs, and for Servables there is a generic Request Capability that determines whether or not an entity is allowed to make HTTP requests to a Servable or not. Granular access control for Servables is not something we provide, but can be implemented within a Servable manually. DTaaS provides an OpenID Connect compliant Identity Provider that can be used by Servables to authenticate the Identity behind an HTTP request, and from there arbitrary access control semantics can be implemented.",
                        "Users can delegate Capabilities in a revocable manner to other Users using a Trust."
                    ]
                },
                {
                    title: "Federation",
                    content: [
                        "The core utility of a Data Trust relies on the ability to securely Federate access to data assets across multiple nodes in a network. These nodes are co-located with data that must stay in place, with Servables working to offer access controlled, proxied access to that data to other Servables on the network. This can be accomplished either by using a Servable to expose the data via some API, or by pushing computation to the data and creating a derived Servable from the data",
                        "Each DTaaS node is a gateway into a Data Trust Domain (dtDomain). Users of a particular node can have Capabilities on objects that exist on other nodes, and users can grant Capabilities to other users that exist on other nodes. Objects that exist on other DTaaS nodes are available via local APIs and proxies, and interaction with those objects works the same way regardless of which node actually runs the object.",
                        "Any DTaaS node can Federate with other nodes to create a dtDomain, but a node can only be a member of one dtDomain at a time."
                    ]
                }
            ]
        },
        {
            id: 1,
            outline: [
                "Prototypes",
                "Manifest"
            ],
            title: "Prototypes",
            text: [
                {
                    title: "Prototypes",
                    content: [
                        "Prototypes are Docker Images that are pushed into the DTaaS Registry and then registered with DTaaS. Once registered, the Prototype becomes available as an object that can be used to create Servables."
                    ]
                },
                {
                    title: "Manifest",
                    content: [
                        "Prototypes are declared as part of a Manifest file read by the SDK to upload the image and register it. An example looks like:",
                        "The ``name`` and ``tag`` refer to the Docker Image that this Prototype represents, and will also be able to be used to refer to this Prototype in Prototype APIs. Any Prototype API that takes an id can also take ``name:tag``. The ``type`` defines the kind of Servable that this Prototype will create. Any ``dependencies`` specified define what other Prototypes this Prototype needs to function. For example, if ``example-pipeline`` wants to create a Dataset using the ``csv-dataset`` Prototype, then that Prototype needs to exist. The keys for ``dependencies`` are arbitrary and can have semantic meaning to the Prototype itself, for example, to be referred to in code to lookup the id of the Prototype it references. The values are Prototype ids of already existing prototypes, or the name of a prototype if multiple Prototypes are being declared in the same Manifest that are dependent. The ``arguments`` field is deprecated and can be ignored. If any ``capabilities`` are required on other Prototypes, they can be defined as shown. It is assumed that if Capabilities are required, then there is a dependency as well.",
                        "If any Capabilities are required on Servables or other objects passed into this Prototype when it is running as a Servable, the ``config-capabilities`` structure can be used to map required Capabilities onto values in your Servables config dict. For example, if the config dict for ``example-pipeline`` looks like this"
                    ]
                }
            ]
        },
        {
            id: 2,
            outline: [
                "Servables",
                "Types",
                "HTTP",
                "Authentication"
            ],
            title: "Servables",
            text: [
                {
                    title: "Servables",
                    content: [
                        "A Servable is an instantiation of a Prototype that when running results in a Kubernetes Pod and Service. The Service exposes the Pod within the Kubernetes Cluster itself. This enables Servables to run non-HTTP services and make those services accessible to other Servables, but does not enable access to them from outside of the cluster."
                    ]
                },
                {
                    title: "Types",
                    content: [
                        "Servables can be one of the following types:",
                        "- App",
                        "- Dataset",
                        "- Model",
                        "- Report",
                        "- Pipeline",
                        "- Daemon ",
                        "With the exception of Pipeline and Daemon, all of these types are equivalent. The reason for different types to exist is that while the implementation is the same, the semantic meaning is not, which matters when it comes to making access control decisions. It is often useful to be able to grant an App some Capabilities on Models, but not on Datasets. These pre-defined types are the only types available, but they should be general enough to model a variety of different domains and use cases.",
                        "Apps, Datasets, Models, and Reports are expected to have HTTP interfaces. Authenticated users with the Request Capability on the Servable will be allowed to make HTTP requests to the Servable.",
                        "Daemons do not expose HTTP interfaces by default, but are instead intended to provide non-interactive services to support other Servables. Pipelines also do not expose an HTTP interface, and are expected to complete a discrete task and then exit, whereas other Servables, including Daemons, are expected to run forever or until they are stopped. There is nothing enforcing this behavior at this time, but it is an expected convention."
                    ]
                },
                {
                    title: "HTTP",
                    content: [
                        "Servables are exposed to DTaaS users through an HTTP Proxy that is able to authenticate them. Access to Servables is routed through different domain prefixes. For example, if DTaaS is deployed to ``dtaas.example.org``, then by default a Servable is accessible on ``servable_id.dtaas.example.org``. Servables that expose HTTP interfaces must do so on port ``8080``.",
                        "Servables are exposed via the same mechanism as a ``dtApp``, with the authentication field being set to an internal ACL service that implements our Capability system. When a Servable is exposed in a node that is part of a ``dtDomain``, then the ``domainSelector`` is set to the ``dtDomain`` that the node is a part of."
                    ]
                },
                {
                    title: "Authentication",
                    content: [
                        "Servables are able to be exposed with or without authentication. Disabling authentication will allow any HTTP request through, even from unauthenticated sources. Enabling authentication will only allow requests from authenticated sources that have the Request Capability on the Servable.",
                        "Disabling authentication does not mean that there is no security around a Servable, only that the Servable is managing its own security semantics. For example, rather than relying on DTaaS Capabilities to determine access control, a Servable can perform its own Authentication the same way any other web application could."
                    ]
                }
            ]
        },
        {
            id: 3,
            title: "Capabilities",
            outline: [
                "Capabilities",
                "Capability Types",
                "Trusts",
                "Domains",
            ],
            text: [
                {
                    title: "Capabilities",
                    content: [
                        "Capabilities are the means through which DTaaS determines whether or not an action may be performed."
                    ]
                },
                {
                    title: "Capability Types",
                    content: [
                        "There are seven different types of capabilities that exist. Some Capabilities have no effect on some object types, and the exact semantics depend on the object type.",
                        "In general, objects have all Capabilities on objects they create, and Users are allowed to :attr:`~sightline.simon.capability.Capability.CREATE` Prototypes and :attr:`~sightline.simon.capability.Capability.DISCOVER` other Users. When a  runs a Servable, DTaaS will prompt for Capabilities that the Servable requires and the User will be able to delegate Capabilities that they have to the Servable. :class:`~sightline.simon.trust.Trust`s can be used to explicitly delegate Capabilities to other Users."
                    ]
                },

                {
                    title: "Trusts",
                    content: [
                        "Trusts are used to allow Users to explicitly delegate Capabilities on objects they have, to other Users that they want to grant those Capabilities to. The User that creates a Trust is referred to as a Trustee, which grants them :attr:`~sightline.simon.capability.Capability.MANAGE` on the Trust and on objects in the Trust. Trustees can add other Users as Trustees.",
                        "Trustees control the content of a Trust and membership of the Trust. Members are Users and contents are other objects. When a Trust is created, it is defined with a set of Capabilities and ensures that all members have that set of Capabilities on all contents of the Trust. Members and contents can be removed, which will cease the delegation."
                    ]
                },
                {
                    title: "Domains",
                    content: [
                        "When a DTaaS node is in a ``dtDomain``, Users on different nodes will be able to Discover each other. This allows adding users to Trusts that exist on other nodes in the Domain. Once a remote User is added, they will have Capabilities on remote objects, and they will be able to interact with those objects via their own DTaaS node as though the object existed locally. DTaaS handles the proxying of access."
                    ]
                }
            ]
        },
        {
            id: 4,
            title: "dtDomains",
            outline: [
                "dtDomains",
            ],
            text: [
                {
                    title: "dtDomains",
                    content: [
                        "A ``dtDomain`` allows you to peer multiple DTaaS nodes together and share ``dtApp`` objects between them. When a ``dtDomain`` is active and has multiple peers connected, those peers will share objects, and API calls will be federated across the domain. This means that when you make an API call to list Models for example, any Model you have Capabilities on, on any node in the domain, will be visible to you from your own node. Users also become visible to each other across the domain, allowing the sharing of objects to other users on other nodes. This facilitates the sharing of objects through Trusts."
                    ]
                },

            ]
        },
        {
            id: 5,
            title: "dtApps",
            outline: [
                "dtApps",
                "serviceSelector",
                "spec",
                "Federated dtApps"
            ],
            text: [
                {
                    title: "dtApps",
                    content: [
                        "A ``dtApp`` is a Kubernetes CRD type that allows exposing Kubernetes services through DTaaS. This is useful when controlling Kubernetes object life cycles through some other means rather than the DTaaS API. For example, it's possible to deploy a web application to Kubernetes with helm, and then expose the web interface to DTaaS users with a ``dtApp``. The Authentication semantics require additional work in this case if authentication is desireable. It is not currently possible to assign Capabilities to a ``dtApp`` directly, so the DTaaS Capability system cannot help. Instead, access control needs to be handled manually either by the web application itself, or with another service that will be called with the NGINX ``auth_request`` directive."
                    ]
                },
                {
                    title: "serviceSelector",
                    content: [
                        "The ``serviceSelector`` is how we can define what service to expose and how to expose it. There are two attributes that can be set are ``name`` and ``port``. ``name`` defines the name of the service we're exposing and ``port`` defines what port for the service we are routing requests to. The default port to redirect to is port ``8080``."
                    ]
                },
                {
                    title: "spec",
                    content: [
                        "The ``spec`` is used to define the type of authentication to apply to the new subdomain in DTaaS. The ``spec`` is composed of an ``authenticationType`` and possibly an ``authenticationURL`` depending on the value of ``authenticationType``. ``authenticationType`` supports ``None``, and ``Custom``, one of which must be set.",
                        "Setting ``authenticationType`` to ``None`` results in no authentication being applied. This means anyone who can make a request to the URL will have that request redirected to the underlying service. This does not prevent any authentication implemented by the underlying service from rejecting incoming requests. This is the equivalent of setting ``authenticate=False`` during Servable creation.",
                        "Setting ``authenticationType`` to ``Custom`` requires defining an authentication service to be used to authorize the request. This URL is accessed using the `nginx auth_request directive <http://nginx.org/en/docs/http/ngx_http_auth_request_module.html>`_ and must conform to its requirements."
                    ]
                },
                {
                    title: "Federated dtApps",
                    content: [
                        "If a DTaaS node is connected to a ``dtDomain`` it's possible to configure a ``dtApp`` such that it exposes the underlying service to the other nodes in the domain. This is done using the ``domainSelector`` section. The ``domainSelector`` field is an object with a single ``name`` key. The value for ``name`` is a list of string names of domains that the app should be accessible within. At this time, a node can only be a member of one domain, and so a ``dtApp`` can only ever be accessible over one domain.",
                        "A ``dtApp`` can also be removed from a federation by removing the name of its domain from the list in the ``domainSelector``."
                    ]
                }
            ]
        },
        {
            id: 6,
            title: "dtServices",
            outline: [
                "dtServices"
            ],
            text: [
                {
                    title: "dtServices",
                    content: [
                        "A ``dtService`` is a Kubernetes CRD that allows creation of Wireguard networks between pods on different nodes of a federation. Each network is identified by the name of the dtService object. Creating a ``dtService`` with a name not already associated with a network will create a new one while using the name of an existing network will cause the associated pod to join the existing network."
                    ]
                }
            ]
        }
    ]
    const sdkData = [
        {
            id: 0,
            title: "CLI Commands and Usage",
            outline: [
                "Commands",
                "Global Options"
            ],
            text: [
                {
                    title: "Commands",
                    content: [
                        "sdk login",
                        "Accepts ``--api-key`` and ``--insecure``. Login will need to be completed using the browser via an OAuth2 flow. Insecure flag is used to configure the login request to use HTTP over HTTPS. The API Key flag is used to provide an API key as credentials. When API key is used as credentials, the global profile flag must also be used to tell the CLI which profile the API key is going to be associated with.",
                        "sdk list-profiles",
                        "Lists the profiles previously configured with ``sdk login``. Also shows which profile is the active profile.",
                        "sdk use-profile <PROFILE_NAME>",
                        "Sets the active profile. First argument must be a string matching the name of a previously configured profile.",
                        "sdk remove-profile <PROFILE_NAME>",
                        "Removes the profile from the current list of configured profiles, and deletes the credentials.",
                        "sdk configure-docker",
                        "Configures the docker daemon to use the SDKs credential helper with the indicated docker registry. This allows for authentication to the DTaaS docker registry using the credentials of the active profile. Accepts the options ``--server`` and ``--port``.",
                        "sdk show",
                        " Displays the information about the current DTaaS project",
                        "sdk types",
                        "Displays a list of the prototype types supported by the current version of the SDK",
                        "sdk package",
                        "Packages the current project into a tarball for upload to DTaaS. Accepts the options ``--pre-built``, ``--no-cache``, ``--output`` and ``exist-ok``. The pre-built option indicates to not build the docker images for the prototypes and instead use the already built versions. This is required if there is no dockerfile defined in the manifest for any of the prototypes. The no-cache option forces the SDK to build the docker images without the use of the docker image cache. Output takes as a value a file name with path to write the resulting tarball to. If not set, the default output is ``default.tar`` in the current directory. The exist-ok option indicates that it's okay to replace an existing file when writing the tarball.",
                        "sdk upload",
                        "Uploads the current project to DTaaS. Accepts the options ``--pre-built``, ``--no-cache`` and ``--path``. The path option accepts as a value a path to a tarball produced by `sdk package`_ to upload. Omitting the path will result in a new tarball being uploaded as if ``sdk package`` were called. See `sdk package`_ for details about what the pre-built and no-cache options do.",
                        "sdk teardown",
                        "Removes all prototypes defined in the current project from the connected DTaaS deployment. This involves deleting all servables instantiated using said prototype and stopping all instances of those servables.",
                        "sdk replace",
                        "Performs an `sdk teardown`_ followed by an `sdk upload`_. Accepts the same options as sdk upload.",
                        "sdk list <TYPE>",
                        "Lists all artifacts of a given servable type. Accepts the option ``--json`` which prints the output as a JSON string.",
                        "sdk describe <ARTIFACT_ID>",
                        " Describes the artifact with the given artifact ID. Accepts the option ``--json`` which prints the output as a JSON string.",
                        "sdk logs <ARTIFACT_ID>",
                        " Retrieves the pod logs for the instance with the supplied artifact ID. Accepts the ``-f`` flag to continuously stream the logs.",
                        "sdk create-key <KEY_NAME>",
                        "Creates an API key for later use as credentials in another CLI session. This API key will not be retrievable after being issued, so don't lose it.",
                        "sdk list-keys",
                        "Lists all the known API keys of the active profile. Only lists the names of the keys, not the keys themselves.",
                        "sdk remove-key <KEY_NAME>",
                        "Removes and revokes the indicated key. That key will no longer be valid for any session. Any profiles using that API key will be invalidated.",
                        "sdk version",
                        " Displays the version of the SDK currently being used",
                    ]
                },
                {
                    title: "Global Options",
                    content: [
                        "``-h``, ``--help``",
                        "Display the help information",
                        "--dir <PATH>",
                        "Selects the path to the project to interact with. Defaults to the current directory.",
                        "simon-domain <DOMAIN>",
                        "Specifies the domain to assume DTaaS is available at.",
                        "simon-auth <TOKEN>",
                        "Sets a token to use for authenticating with DTaaS.",
                        "profile <PROFILE>",
                        "Sets which profile name to use when executing a command.",
                        "--verbose",
                        "Enables debug level logging of SDK commands.",
                    ]
                }
            ]
        },
        {
            id: 1,
            title: "Example",
            outline: [
                "Example",
                "SDK Authentication",
                "Design Guidelines",
                "Example Project",
                "Overall Project Flow",
                "Step 1: Project Requirements and Architecture",
                "Step 2: Create a DTaaS project",
                "Step 3: Prototype Development",
                "Step 4: Uploading and Running the Project"
            ],
            text: [
                {
                    title: "Example",
                    content: [
                        "The DTaaS API follows the OpenAPI spec, and documentation is self-hosted under ``/v1/doc``. In this example, we will be using the Python SDK that implements an interface to this API, but the API can be used independently of the Python SDK if it's desireable to interface with DTaaS from other languages."
                    ]
                },
                {
                    title: "SDK Authentication",
                    content: [
                        "The SDK is capable of functioning from within DTaaS Servables automatically, or externally provided you can authenticate to DTaaS. From within the context of a running Servable, the SDK is automatically authenticated because an API key for that Servable is created by DTaaS and mounted in the Servable as a secret. From outside of that context, the ``sdk login`` command can be used to obtain authentication tokens.",
                        "The SDK also has the concept of Profiles. Low level Python profile management documentation can be found `here <./api/config.html>`_. The CLI can interact with Profiles through the following commands:",
                        "sdk login",
                        "sdk list-profiles",
                        "sdk use-profile",
                        "sdk remove-profile",
                        "All other CLI commands accept ``--profile`` as an argument, i.e. ``sdk --profile profilename list apps`` in order to execute that command as the profile 'profilename'. When using ``sdk login``, ``--profile`` sets which profile will be set up with the given credentials, either username/password or API key. ``sdk list-profiles`` lists all profiles, and indicates which profile is currently active. ``sdk use-profile`` updates which profile is the active profile, while ``sdk remove-profile`` removes the currently active profile."
                    ]
                },
                {
                    title: "Design Guidelines",
                    content: [
                        "A Data Trust Application should be designed so that individual Data Assets are Servables, and the user-facing Application coordinates the lifecycle of and sharing of those Servables among users. The stock DTaaS Admin Dashboard can provide that functionality on a generic level, but building a customized Application allows for a better user experience.",
                        "The DTaaS platform makes building certain kinds of systems easier by providing tools to solve common problems:",
                        "- Not all Users have access to the Application running on a single Server.",
                        "- Data lives in multiple locations and must stay in place.",
                        "- No pre-existing centralized Identity Management or Access Control",
                    ]
                },
                {
                    title: "Example Project",
                    content: [
                        "This page gives an introduction to DTaaS modules, and a minimal example project. This example assumes you have the sdk installed and have access to a running DTaaS instance. A basic understanding of Docker and Python3 will also be required to follow along.",

                    ]
                },
                {
                    title: "Overall Project Flow",
                    content: [
                        "The general steps to develop a DTaaS project are as follows:",
                        "| `Step 1: Project Requirements and Architecture`_ describing how each prototype will interact.",
                        "| `Step 2: Create a DTaaS project`_ through SDK.",
                        "| `Step 3: Prototype Development`_ with the fewest number of dependencies.",
                        "| `Step 4: Uploading and Running the Project`_ through the SDK.",
                        "It is important to know how many prototypes the project requires, and what kind of communication they will require. This will help distinguish which of the prototype types are best fit for the project."
                    ]
                },
                {
                    title: "Step 1: Project Requirements and Architecture",
                    content: [
                        "This project will demonstrate how to use a Pipeline, App, and Daemon, and show how they interact with each other using DTaaS. It will be a minimal example that displays possible interactions.",
                        "The purpose of a Daemon is to provide a service to be used by other Servables. Communication with a Daemon happens directly between Pods and Services in Kubernetes.",
                        "Our example Daemon will be a simple HTTP server.",
                        "An App is meant to be a user-facing web application. Our example App will be a very simple application that collects data from the Daemon, and displays the data to the user.",
                        "Pipelines are intended to complete some kind of task. Our example Pipeline will coordinate the other two prototypes. First it will create and run the Daemon, followed by creating and running the App while configuring the App to interface with the Daemon.",
                    ]
                },
                {
                    title: "Step 2: Create a DTaaS project",
                    content: [
                        "A DTaaS project consists of a Manifest file that declares Prototypes. We will use the following Manifest:",
                        "In this example, we will be building the docker images ourselves. The name and tag specified will be how we tag the images we build so that the SDK can find and upload them.",
                        "Specifying the dependencies allows DTaaS to ensure that a Prototype cannot be removed from the system until all other Prototypes that depend on it are also removed.",
                        "Because ``hello-pipeline`` is responsible for deploying ``hello-app`` and ``hello-daemon`` it requires Create Capability on them. Because the ``hello-app`` is going to be interfacing with the running Daemon, it wants Read Capability on it to be able to check that the Daemon exists and is running, and to find its address to make requests to it. Because we don't know the id of the Daemon until runtime, this Capability is requested with ``config-capabilities``, and says that we will need Read Capability on the value of the ``daemon-id`` key in our config. The Pipeline will have to provide that config when it creates the App."
                    ]
                },
                {
                    title: "Step 3: Prototype Development",
                    content: [
                        "Daemon",
                        "This sets up a Flask server to listen on address 0.0.0.0 and port 8080 A HTTP. GET request against /hello should return a HTTP 200 OK response, with a body of 'Hello, DTaaS!'. Because Daemon objects are not exposed through the DTaaS HTTP proxy, any port or protocol could be used here. It is up to the caller of the Daemon to understand the interfaces that the Daemon exposes.",
                        "App",
                        "Because Apps are meant to be served to end users in a browser, the requirements for an App are more constrained. HTTP is mandatory, as is running the server on port 8080 so that the DTaaS HTTP proxy can expose it.",
                        "Web applications also have concerns about authentication and authorization. DTaaS has two mechanisms to deal with this, an automatic authenticating proxy and an OpenID Connect compliant Identity Provider that Apps can use to authenticate DTaaS users. The DTaaS HTTP proxy can enforce access control for Apps, but that enforcement only works at a very coarse level. DTaaS does not understand the HTTP API exposed by Apps, and only provides the ability to allow deny all HTTP requests. If granular access control is desired, then the automatic access control can be disabled on App creation and the App can use OpenID Connect with the DTaaS Identity Provider to authenticate users.",
                        "In this example, because our API is simple we rely on automatic access control and do not write any additional code to work with granular access control.",
                        "This App also includes the Sightline SDK. The SDK interfaces with the DTaaS API to discover the Daemon address, allowing the App to talk to the Daemon. The config object from the SDK is the configuration passed into the ``App.create`` call in the Pipeline to create the App, which is where we are provided with the ``daemon-id``. To receive a copy of the SDK for use with DTaaS, contact your Sightline sales representative.",
                        "Pipeline",
                        "The Pipeline runs a deployment of the Daemon and App, demonstrating DTaaS object creation and Capability delegation. This isn't strictly necessary to perform as a Pipeline. It is possible and reasonable for this use case to write a stand-alone deployment script that is identical to the pipeline. The only difference is that when running a stand-alone script outside of DTaaS, the SDK has to authenticate first so that it can obtain the necessary access token to make API calls."
                    ]
                },
                {
                    title: "Step 4: Uploading and Running the Project",
                    content: [
                        "In order to upload the Prototypes, first use docker to build the images and tag them the same way that the Manifest refers to them.",
                        "To upload the Prototypes, you first need an authenticated context to be able to use the SDK to interact with DTaaS. The canonical way to do this is to authenticate, and then export the authentication context into your environment. From there it will be used by future calls to the SDK, or by child processes that inherit your environment variables (for example, calling into the API from a Python script).",
                        "This may require the use of a web browser to complete, as DTaaS can be configured to require authentication with a 3rd-party Identity Provider.",
                        "Because we want to upload Prototypes, we also have to configure our local docker client to be authenticated with the DTaaS registry. This can be done with",
                        "Where ``[host]`` and ``[port]`` are substituted by the deployment location of DTaaS and the port used to expose the registry (by default, usually 5000).",
                        "Once your project is uploaded, we can run it. We'll be launching the Pipeline from a stand-alone Python script.",
                        "After the Pipeline has completed, the App can be found by either by finding the App in the outputs field of the Pipeline, or by selecting the correct App from :code:`App.list()`. The App's URL can be found from :code:`app.url`, and visiting that URL in a browser should display the App that you defined earlier. You can also access the App by selecting it in the UI.",
                    ]
                }
            ]
        },
        {
            id: 2,
            title: "Project Manifest",
            outline: [
                "Project Manifest",
                "Required Values",
                "Optional Values",
                "Type Specific Arguments",
                "Daemons"
            ],
            text: [
                {
                    title: "Project Manifest",
                    content: [
                        "A DTaaS project manifest is a JSON file containing information about a set of prototypes. Through the manifest, the SDK is able to load the defined prototypes into DTaaS for use. The project manifest must be named either ``MANIFEST`` or ``MANIFEST.json``.",
                        "The top level keys in the manifest are the names of the prototypes in the project, with their values being the prototype metadata.",
                    ]
                },
                {
                    title: "Required Values",
                    content: [
                        "name",
                        "The name of the prototype. This must match the top level key.",
                        "tag",
                        "The tag associated with the current version of the prototype.",
                        "type",
                        "The object type in DTaaS. The list of valid types may be retrieved using the :ref:`sdk types<public/sdk/cli:Commands>` command.",
                        "dependencies",
                        "A mapping of prototypes to act as dependencies. The keys should be a name that is understood by the prototype code and the value a reference to the prototype that is a dependency. The value may be an artifact ID of an existing prototype or the name of another prototype in the same project.",
                        "arguments",
                        "A JSON mapping used to configure values for specific prototype types.",
                        "capabilities",
                        " A mapping of artifacts to a list of capabilities we require on said artifact. The key may be an artifact ID or the name of a prototype defined in the same manifest. The value must be a list of the capabilities needed on the key. An explanation of the valid capabilities and their meanings may be found :ref:`here<public/dtaas/capabilities:Capabilities>`.",
                        "config-capabilities",
                        "Used to define capabilities for artifacts that aren't known at creation time. The structure must mirror the structure of the config required by the prototype. Where the list of capabilities are provided here, an artifact ID must be provided in the actual config. This will result in the capabilities defined here being granted on the artifact defined in the config."
                    ]
                },
                {
                    title: "Optional Values",
                    content: [
                        "dockerfile",
                        "A path to a dockerfile used to build the images for the prototype. If not included, the SDK will be unable to build the images for the prototype and will require they be pre-built with the correct name and tag.",
                        "context",
                        "A path to a directory to be used as the build context when building the docker images for the prototype."
                    ]
                },
                {
                    title: "Type Specific Arguments",
                    content: [
                        "Some types of prototypes require certain values be included in their ``arguments`` section."
                    ]
                },
                {
                    title: "Daemons",
                    content: [
                        "volume_location",
                        "The absolute path within the container to mount the daemon's persistent volume.",
                        "volume_size",
                        "The size in megabytes for the daemon's persistent volume."
                    ]
                }
            ]
        }
    ]
    const clickDtaas = () => {
        setShowDtaas(!showDtaas);
        setShowSdk(false);
        setListContent(listData)
        setOutlineStatus(0);
        setSelectStatus(0)
        setData(listData)
    }
    const clickSdk = () => {
        setShowSdk(!showSdk)
        setShowDtaas(false)
        setListContent(sdkData)
        setOutlineStatus(0);
        setSelectStatus(0);
        setData(sdkData)
    }

    const [listContent, setListContent] = useState(listData)
    const [data,setData] = useState(listData)
    // useEffect(() => {
    //     if (typeof window !== "undefined") {
    //         window.addEventListener("scroll", getScroll)
    //     }
    // })
    // const getScroll = (e) => {
    //     let scrollTop = (e.srcElement ? e.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (e.srcElement ? e.srcElement.body.scrollTop : 0);
    //     console.log(scrollTop);
    //     if (scrollTop > 169 && scrollTop < 643) {
    //         setOutlineStatus(0)
    //     } else if (scrollTop > 643) {
    //         setOutlineStatus(1)
    //     }
    // }
    const keyDown = (e)=>{
        if(e.keyCode===13){
            setShowSearch(true)
        }
    }
    const changeColor = (index) => {
        setSelectStatus(index)

    }
    const changeOutline = (index) => {
        setOutlineStatus(index)
    }
    return (
        <div className='documentation'>
            <Header />
            <SearchPage 
            showSearch={showSearch} 
            setValue={setSearchValue} 
            changeOutline={changeOutline} 
            changeColor={changeColor} 
            listData={data} 
            sdkData={sdkData} 
            status={showSearch} 
            value={searchValue} 
            setValue={setSearchValue} 
            close={setShowSearch} />
            <div className='navigation'>
                {/* <div className='search'>
                    <h3 onClick={() => navigate("/")}>Sightline Platform</h3>
                    <img src={search} alt="" />
                </div> */}
                <div onClick={clickDtaas} className={`${showDtaas ? "active" : "select"} button`}>
                    <img className='book' src={showDtaas ? book : blackBook} alt="" />
                    DTaaS
                    {/* <img style={{ transform: showDtaas ? "translateY(-50%) rotate(90deg)" : "translateY(-50%) rotate(0)" }} className='selectImg' src={arrowRight} alt="" /> */}

                    <svg style={{ transform: showDtaas ? "translateY(-50%) rotate(90deg)" : "translateY(-50%) rotate(0)" }} className='selectImg' src={arrowRight} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={showDtaas ? "white" : "black"} fill-rule="evenodd" clip-rule="evenodd" d="M4.41233 6.00021L0.015625 1.23467L1.11809 0.217529L6.45323 6.00024L1.11808 11.7826L0.0156403 10.7655L4.41233 6.00021Z" />
                    </svg>
                </div>
                <ul style={{ display: showDtaas ? "flex" : "none" }}>
                    {
                        dtaasList.map((item, index) => {
                            return (
                                <li style={{ display: showDtaas ? "block" : "none" }} onClick={() => changeColor(index)} className={selectStatus === index ? "blue" : ""} key={index}>{item}</li>
                            )
                        })
                    }
                </ul>
                <div onClick={clickSdk} className={`${showSdk ? "active" : "select"} button`}>
                    <img className='book' src={showSdk ? book : blackBook} alt="" />
                    SDK
                    {/* <img className='selectImg' style={{ transform: showSdk ? "translateY(-50%) rotate(90deg)" : "translateY(-50%) rotate(0)" }} src={arrowRight} alt="" /> */}
                    <svg style={{ transform: showSdk ? "translateY(-50%) rotate(90deg)" : "translateY(-50%) rotate(0)" }} className='selectImg' src={arrowRight} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={showSdk ? "white" : "black"} fill-rule="evenodd" clip-rule="evenodd" d="M4.41233 6.00021L0.015625 1.23467L1.11809 0.217529L6.45323 6.00024L1.11808 11.7826L0.0156403 10.7655L4.41233 6.00021Z" />
                    </svg>
                </div>
                <ul style={{ display: showSdk ? "flex" : "none" }}>
                    {
                        sdkList.map((item, index) => {
                            return (
                                <li style={{ display: showSdk ? "block" : "none" }} onClick={() => changeColor(index)} className={selectStatus === index ? "blue" : ""} key={index}>{item}</li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='phone_screen'>
                <div className='screen'  onClick={() => setShowSelection(true)}>
                    <img src={screen} alt="" />
                </div>
                <div className='input'>
                    <input onKeyDown={keyDown} placeholder='Search' type="text" name="" id="" />
                    <div className='magnifier' onClick={() => setShowSearch(true)}>
                        <img src={search} alt="" />
                    </div>
                </div>
            </div>



            <div onClick={() => setShowSelection(false)} className='phone_mask' style={{ display: showSelection ? "block" : "none" }}></div>
            <div className='selection' style={{ display: showSelection ? "block" : "none" }}>
                <div className='line'></div>
                <div className={`${showDtaas ? "phone_active" : "phone_default"} phone_botton`}
                    onClick={() => {
                        setShowDtaas(!showDtaas);
                        setShowSdk(false);
                        setListContent(listData)
                        changeColor(0)
                    }}
                >
                    {/* book */}
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={showDtaas ? "white" : "black"} d="M6.325 19.75H2.425C1.225 19.75 0.25 18.775 0.25 17.575V2.42502C0.25 1.22502 1.225 0.250024 2.425 0.250024H6.325C7.525 0.250024 8.5 1.22502 8.5 2.42502V17.575C8.5 18.775 7.525 19.75 6.325 19.75ZM2.425 1.75002C2.05 1.75002 1.75 2.05002 1.75 2.42502V17.575C1.75 17.95 2.05 18.25 2.425 18.25H6.325C6.7 18.25 7 17.95 7 17.575V2.42502C7 2.05002 6.7 1.75002 6.325 1.75002H2.425ZM14.2 20.0125C13.225 20.0125 12.3625 19.375 12.1 18.4L8.1625 3.77502C7.8625 2.61252 8.5375 1.41252 9.7 1.11252L13.4875 0.100024C14.05 -0.0499756 14.65 0.0250244 15.1375 0.325024C15.625 0.625024 16 1.07502 16.15 1.63752L20.0875 16.2625C20.3875 17.425 19.7125 18.625 18.55 18.925L14.7625 19.9375C14.575 19.975 14.3875 20.0125 14.2 20.0125ZM14.05 1.48752C13.975 1.48752 13.9375 1.48752 13.8625 1.52502L10.075 2.53752C9.7 2.65002 9.5125 2.98752 9.5875 3.36252L13.525 17.9875C13.6375 18.3625 13.975 18.55 14.35 18.475L18.1375 17.4625C18.5125 17.35 18.7 17.0125 18.625 16.6375L14.6875 2.01252C14.65 1.82502 14.5375 1.67502 14.3875 1.60002C14.275 1.52502 14.1625 1.48752 14.05 1.48752Z" />
                    </svg>
                    DTaas
                    {/* arrowRight */}
                    <svg style={{ transform: showDtaas ? "translateY(-50%) rotate(90deg)" : "translateY(-50%) rotate(0)" }} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={showDtaas ? "white" : "black"} fill-rule="evenodd" clip-rule="evenodd" d="M4.41208 6.00009L0.0153809 1.23454L1.11785 0.217407L6.45299 6.00012L1.11783 11.7825L0.0153961 10.7653L4.41208 6.00009Z" />
                    </svg>
                </div>
                <ul className='dtaasList' style={{ display: showDtaas ? "flex" : "none" }}>
                    {
                        dtaasList.map((item, index) => {
                            return (
                                <li style={{ display: showDtaas ? "block" : "none" }} onClick={() => {
                                    changeColor(index)
                                    setShowSelection(false)
                                }} className={selectStatus === index ? "blue" : ""} key={index}>{item}</li>
                            )
                        })
                    }
                </ul>
                <div className={`${showSdk ? "phone_active" : "phone_default"} phone_botton`}
                    onClick={() => {
                        setShowSdk(!showSdk)
                        setShowDtaas(false)
                        setListContent(sdkData)
                        changeColor(0)
                    }}
                >
                    {/* book */}
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={showSdk ? "white" : "black"} d="M6.325 19.75H2.425C1.225 19.75 0.25 18.775 0.25 17.575V2.42502C0.25 1.22502 1.225 0.250024 2.425 0.250024H6.325C7.525 0.250024 8.5 1.22502 8.5 2.42502V17.575C8.5 18.775 7.525 19.75 6.325 19.75ZM2.425 1.75002C2.05 1.75002 1.75 2.05002 1.75 2.42502V17.575C1.75 17.95 2.05 18.25 2.425 18.25H6.325C6.7 18.25 7 17.95 7 17.575V2.42502C7 2.05002 6.7 1.75002 6.325 1.75002H2.425ZM14.2 20.0125C13.225 20.0125 12.3625 19.375 12.1 18.4L8.1625 3.77502C7.8625 2.61252 8.5375 1.41252 9.7 1.11252L13.4875 0.100024C14.05 -0.0499756 14.65 0.0250244 15.1375 0.325024C15.625 0.625024 16 1.07502 16.15 1.63752L20.0875 16.2625C20.3875 17.425 19.7125 18.625 18.55 18.925L14.7625 19.9375C14.575 19.975 14.3875 20.0125 14.2 20.0125ZM14.05 1.48752C13.975 1.48752 13.9375 1.48752 13.8625 1.52502L10.075 2.53752C9.7 2.65002 9.5125 2.98752 9.5875 3.36252L13.525 17.9875C13.6375 18.3625 13.975 18.55 14.35 18.475L18.1375 17.4625C18.5125 17.35 18.7 17.0125 18.625 16.6375L14.6875 2.01252C14.65 1.82502 14.5375 1.67502 14.3875 1.60002C14.275 1.52502 14.1625 1.48752 14.05 1.48752Z" />
                    </svg>
                    SDK
                    {/* arrowRight */}
                    <svg style={{ transform: showSdk ? "translateY(-50%) rotate(90deg)" : "translateY(-50%) rotate(0)" }} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={showSdk ? "white" : "black"} fill-rule="evenodd" clip-rule="evenodd" d="M4.41208 6.00009L0.0153809 1.23454L1.11785 0.217407L6.45299 6.00012L1.11783 11.7825L0.0153961 10.7653L4.41208 6.00009Z" />
                    </svg>
                </div>
                <ul className='sdkList' style={{ display: showSdk ? "flex" : "none" }}>
                    {
                        sdkList.map((item, index) => {
                            return (
                                <li style={{ display: showSdk ? "block" : "none" }} onClick={() => {
                                    changeColor(index)
                                    setShowSelection(false)
                                }} className={selectStatus === index ? "blue" : ""} key={index}>{item}</li>
                            )
                        })
                    }
                </ul>
            </div>




            <div id='documentation_content' className='documentation_content'>
                <div className='documentation_content_text'>
                        <h3>{listContent[selectStatus]?.title || ''}</h3>
                    <hr />
                    {
                        listContent[selectStatus]?.text.map((item, index) => {
                            return (
                                <>
                                    <div className='text' id={"text" + index}>
                                            <h3>{item?.title || ''}</h3>
                                        {
                                            item.content.map((item, index) => {
                                                return (
                                                    <p key={index}>{item || ''}</p>
                                                )
                                            })
                                        }
                                    </div>
                                    <hr />
                                </>
                            )
                        })
                    }
                    {/* <div className='text' id='text1'>
                        <h3>What is a Data Trust</h3>
                        <p>DTaaS is a platform for building Data Trusts, and a Data Trust contains Data Assets and Services and Applications built to consume and produce those Data Assets. A Data Asset in DTaaS is any arbitrary data that is exposed to some set of consumers with some API. This means that Data Assets are really code that knows how to store/access data, and that data may live somewhere else.</p>
                        <p>The important distinction between a Data Trust and any other distributed system is the granular visibility and control of data assets and derived data assets that is independent of the Application that generated them. Because DTaaS is a platform for building Data Trusts, it does not define what Data Assets are, but instead provides the architecture necessary to define what they are and how they can be used. This allows Applications to be written for DTaaS that expose arbitrary functionality to users, but still be able to grant users visibility into their data assets independently of the Application that created and manages them. DTaaS decouples the problem of cataloging, managing, and sharing data assets from the problem of generating, viewing, and interacting with them. The former set of problems are managed by DTaaS and the later are left up to Applications to implement.</p>
                    </div>
                    <hr />
                    <div className='text' id='text2'>
                        <h3>Object Model</h3>
                        <p>DTaaS has a graph-based object model that can be used to model arbitrary application domains and apply access control to the objects in that domain. This abstract model is reified into a few pre-defined object types that we provide an SDK for that allows for further domain-specific implementation on top of those concepts. </p>
                        <p>The basic building blocks revolve around Kubernetes concepts.</p>
                    </div> */}
                    {/* <hr /> */}
                    <img src={bigImg} alt="" />
                    <hr className='last_hr' />
                    <div className='documentation_content_botton'>
                        <div onClick={() => {
                            if (selectStatus === 0) {
                                setSelectStatus(listContent.length - 1)
                            } else {
                                setSelectStatus(selectStatus - 1)
                            }
                            navigate("#text0")
                        }}>
                            <img src={arrow} alt="" />
                            <span>{listContent[selectStatus - 1 < 0 ? listContent.length - 1 : selectStatus - 1]?.title}</span>
                        </div>
                        <div onClick={() => {
                            if (selectStatus === listContent.length - 1) {
                                setSelectStatus(0)
                            } else {
                                setSelectStatus(selectStatus + 1)
                                navigate("#text0")
                            }
                        }}>
                            <span>{listContent[selectStatus + 1 > listContent.length - 1 ? 0 : selectStatus + 1]?.title}</span>
                            <img style={{ transform: "rotate(180deg)" }} src={arrow} alt="" />
                        </div>
                    </div>
                </div>

            </div>
            <div className='outline'>
                <h3>Outline</h3>
                <ul>
                    {
                        listContent[selectStatus]?.outline.map((item, index) => {
                            return (
                                <li key={index}>

                                    {
                                        outlineStatus === index ?
                                            <img className='point2' src={bluePoint} alt="" /> :
                                            <img className='point1' src={point} alt="" />
                                    }
                                    <a onClick={() => changeOutline(index)} className={outlineStatus === index ? "blue" : ""} href={`#text${index}`}>{item}</a>
                                </li>
                            )
                        })
                    }
                </ul>
                <div className="search">
                    <input onKeyDown={keyDown} onChange={(e) => { 
                        setSearchValue(e.target.value) 
                        console.log(e);
                        }} type="text" placeholder='search' value={searchValue} />
                    <div>
                        <img onClick={() => setShowSearch(true)} src={search} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
